/* global google */

import React from "react";
import styles from "./Autocomplete.module.scss";
import axios from "axios";
import { FormattedMessage } from "react-intl";
import _ from "lodash";

class Autocomplete extends React.Component {
  constructor(props) {
    super(props);

    this.autocomplete = null;
    this.autocompleteInput = React.createRef();
    this.valuePlaceholder = "";

    this.debouncedLoadSuggestedPlaces = _.debounce(
      this.loadSuggestedPlaces,
      500
    );
  }

  componentDidUpdate = (prevProps) => {
    if (
      this.props.autoCompleteParams !== prevProps.autoCompleteParams &&
      this.props.autoCompleteParams !== undefined
    ) {
      if (
        this.props.autoCompleteParams &&
        this.props.autoCompleteParams.noBias
      ) {
        this.setState({
          types: ["geocode", "establishment"],
          autocompleteInput: React.createRef(),
        });
      } else if (
        this.props.autoCompleteParams &&
        this.props.autoCompleteParams.autoCompleteBias &&
        this.props.autoCompleteParams.autoCompleteBias.southwest &&
        this.props.autoCompleteParams.autoCompleteBias.northeast
      ) {
        const southwest = {
          lat: this.props.autoCompleteParams.autoCompleteBias.southwest[0],
          lng: this.props.autoCompleteParams.autoCompleteBias.southwest[1],
        };
        const northeast = {
          lat: this.props.autoCompleteParams.autoCompleteBias.northeast[0],
          lng: this.props.autoCompleteParams.autoCompleteBias.northeast[1],
        };

        this.setState({
          types: ["geocode", "establishment"],
          locationBounds: new google.maps.LatLngBounds(southwest, northeast),
          autocompleteInput: React.createRef(),
        });
      } else {
        // This should theoretically never get hit, but on the off-chance it does, we want to use global values.
        this.setState({
          types: ["geocode", "establishment"],
          autocompleteInput: React.createRef(),
        });
      }
    }
  };

  _handleInputChange = (e) => {
    this.props.onChange(e);
    if (e.target.value.length > 2) {
      if (!this.props.disableGoogleSuggestionList) {
        this.debouncedLoadSuggestedPlaces(e.target.value, (predictions) => {
          this.props.onPlaceChanged(predictions);
        });
      }
    } else {
      this.props.onPlaceChanged([]);
    }
  };

  handleKeyPress = (event) => {
    if (event.key === "Enter") {
      var customPlace = this.props.autoCompleteCustomPlaces?.find(
        (element) =>
          element.name.toLowerCase() === event.target.value.toLowerCase()
      );
      if (customPlace === undefined) {
        this.loadSuggestedPlaces(event.target.value, (predictions) => {
          this.props.setPrediction(predictions[0]);
        });
      } else {
        this.props.setPrediction({
          customPlace: true,
          title: customPlace.description,
          latitude: customPlace.latitude,
          longitude: customPlace.longitude,
        });
      }
    }
  };

  // Loads the suggested locations, and passes them back using a callback.
  loadSuggestedPlaces = (forValue, resultCallback) => {
    if (this.props.suggestionSource === "google") {
      this.loadGoogleSuggestedPlaces(forValue, resultCallback);
    } else if (
      this.props.suggestionSource === "hereApi" ||
      this.props.suggestionSource === "photon"
    ) {
      this.loadAutoSuggestedPlaces(forValue, resultCallback);
    }
  };

  // Loads the suggested locations using google, and passes them back using a callback.
  loadGoogleSuggestedPlaces = (forValue, resultCallback) => {
    const service = new google.maps.places.AutocompleteService();

    const options = {
      input: forValue,
    };

    if (this?.state?.types) {
      options.types = this.state.types;
    }
    if (this?.state?.locationBounds) {
      options.bounds = this.state.locationBounds;
    }

    service.getPlacePredictions(options, (googlePredictions, status) => {
      if (status === "OK") {
        var predictions = googlePredictions.map((googlePrediction) => ({
          title: googlePrediction.structured_formatting.main_text,
          description: googlePrediction.description,
          placeId: googlePrediction.place_id,
        }));

        resultCallback(predictions);
      }
    });
  };

  // Loads the suggested locations using the here api, and passes them back using a callback.
  loadAutoSuggestedPlaces = (forValue, resultCallback) => {
    var target;

    if (
      this.props.autoCompleteParams.autoCompleteBias &&
      this.props.autoCompleteParams.autoCompleteBias.centrepoint
    ) {
      target = `${this.props.autoCompleteParams.autoCompleteBias.centrepoint[0]},${this.props.autoCompleteParams.autoCompleteBias.centrepoint[1]}`;
      console.log("Target ", target);
    } else if (
      this.props.autoCompleteParams.autoCompleteBias &&
      this.props.autoCompleteParams.autoCompleteBias.southwest &&
      this.props.autoCompleteParams.autoCompleteBias.northeast
    ) {
      // Use the bounding box in the group settings to decide the autocomplete bias.
      var bias = this.props.autoCompleteParams.autoCompleteBias;
      target = `bbox:${bias.southwest[1]},${bias.southwest[0]},${bias.northeast[1]},${bias.northeast[0]}`;
      console.log("target is bbox");
    } else {
      // Use the center of the current viewport to decide the autocomplete bias.
      var approxLoc = this.props.suggestionApproximateLocation;
      target = `${approxLoc.latitude},${approxLoc.longitude}`;
      console.log("target is centrepoint of viewpoint");
    }

    axios
      .get(
        process.env.REACT_APP_API_BASE_URL +
          `autosuggest/${forValue}/${target}/${this.props.destinationGroupId}`,
        {
          headers: {
            Authorization: this.props.apiToken,
          },
        }
      )
      .then((response) => {
        var predictions = response.data.map((autosuggestPrediction) => ({
          title: autosuggestPrediction.title,
          description: autosuggestPrediction.address.label,
          latitude: autosuggestPrediction.position.lat,
          longitude: autosuggestPrediction.position.lng,
          id: autosuggestPrediction.id,
          groupName: autosuggestPrediction.groupName,
          stopPointType: autosuggestPrediction.stopPointType
        }));
        resultCallback(predictions);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  render = () => {
    var isAutoCompleteActivated =
      this.props.value &&
      this.autocompleteInput &&
      this.autocompleteInput.current &&
      this.autocompleteInput.current.value;
    var isValueUpdatingRequired =
      isAutoCompleteActivated &&
      this.autocompleteInput.current.value !== "" &&
      this.autocompleteInput.current.value !== this.props.value;

    if (isValueUpdatingRequired) {
      this.autocompleteInput.current.value = this.props.value;
    }

    return (
      <>
        <FormattedMessage id="enterLocation">
          {(placeholder) => (
            <input
              ref={this.autocompleteInput}
              aria-label={this.props.arialabel}
              type="search"
              id={this.props.id}
              name={this.props.name}
              placeholder={this.props.valuePlaceholder || placeholder}
              autoComplete="off"
              autoCorrect="off"
              spellCheck="false"
              className={styles["form-input"]}
              onBlur={this.props.onBlur}
              onFocus={this.props.onFocus}
              onClick={this.props.onClick}
              onChange={this._handleInputChange}
              onKeyDown={this.handleKeyPress}
            />
          )}
        </FormattedMessage>
      </>
    );
  };
}

export default Autocomplete;
