import { useEffect, useState, useContext } from "react";
import moment from "moment";
import "moment/min/locales";
import Modal from "../../common/Modal";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import styles from "./OptionsModal.module.scss";
import TimeField from "react-simple-timefield";
import useCollapse from "react-collapsed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useIntl } from "react-intl";

import { ReactComponent as Accessibility } from "../../assets/Step_free.svg";
import { ReactComponent as AccessibilityActive } from "../../assets/Step_free_on.svg";
import { ReactComponent as Bicycle } from "../../assets/Bicycle.svg";
import { ReactComponent as BicycleActive } from "../../assets/Bicycle_w.svg";
import { ReactComponent as GuideDog } from "../../assets/Guidedog.svg";
import { ReactComponent as GuideDogActive } from "../../assets/Guidedog_w.svg";
import { ReactComponent as Luggage } from "../../assets/Luggage.svg";
import { ReactComponent as LuggageActive } from "../../assets/Luggage_w.svg";
import EditPassengers from "../../EditPassengers";
import EditElectricVehicles from "../../EditElectricVehicles";
import EditPublicTransport from "../../EditPublicTransport";

import { FormattedMessage } from "react-intl";
import { ThemeContext } from "../../../Context/ThemeContext";

function Collapse(props) {
  const collapseProps = useCollapse(props);
  return props.children(collapseProps);
}

const OptionsModal = (props) => {
  const intl = useIntl();
  const [language, setLanguage] = useState("en");
  const [isMobile, setMobile] = useState(false);
  const [height, setHeight] = useState(window.innerHeight);

  const theme = useContext(ThemeContext);
  const {
    journeyPlan,
    time: dateTime,
    returnTime: returnDateTime,
    isEvDestinationGroup,
    isPTOptions,
  } = props;

  function handleWindowSizeChange() {
    setHeight(window.innerHeight);
    if (window.innerWidth <= 768 || window.innerHeight <= 890) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  }

  useEffect(() => {
    handleWindowSizeChange();
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setLanguage(props.lang);
  }, [props.lang]);

  // TODO: Move this somewhere nicer!
  const inputTheme = (theme) =>
    createTheme({
      ...theme,
      typography: {
        fontFamily: "Circular, -apple-system, BlinkMacSystemFont, Roboto",
      },
      palette: {
        primary: {
          main: "#008489",
        },
      },
      components: {
        MuiTextField: {
          styleOverrides: {
            root: {
              borderRadius: "0px",
              borderWidth: "0px",
              border: "0px",
              minWidth: "100%",
            },
          },
        },
        MuiInputBase: {
          styleOverrides: {
            input: {
              color: "#767676",
              fontWeight: "200",
              lineHeight: "1.44444em",
              fontSize: "19px",
              maxHeight: "16px",
            },
          },
        },
        MuiIconButton: {
          styleOverrides: {
            root: {
              color: "#767676",
            },
          },
        },
        MuiDateCalendar: {
          styleOverrides: {
            root: {
              minWidth: isMobile ? null : "378px",
            },
          },
        },
      },
    });

  const {
    onDatePickerDateChange,
    onReturnDatePickerDateChange,
    handleTimeChange,
    handleReturnTimeChange,
    handleAdultFilterQuantity,
    handleChildFilterQuantity,
    handleFilter,
    handleRemainingRangeFilterQuantity,
    handleMaximumRangeFilterQuantity,
    handleChargingTimeFilterQuantity,
    setIsReturn,
  } = props;
  const {
    handleChademoFilter,
    handleComboCCSFilter,
    handleRapidAC43Filter,
    handleBusFilter,
    handleTramFilter,
    handleTrainFilter,
  } = props;
  const {
    state,
    toggleArrive,
    toggleDepart,
    toggleReturnArrive,
    toggleReturnDepart,
    toggleModal,
    updateResults,
  } = props;

  /* Render */
  const hideTitle =
    theme?.hideClientElements?.includes("hideStepFree") &&
    theme?.hideClientElements?.includes("hideHeavyLuggage") &&
    theme?.hideClientElements?.includes("hideGuideDog") &&
    theme?.hideClientElements?.includes("hideBikePark");

  return (
    <Modal
      title={intl.formatMessage({ id: "travelFilters" })}
      close={toggleModal}
      finish={() => {
        updateResults();
      }}
      updateText={intl.formatMessage({ id: "update" })}
      shouldOverflow={true}
    >
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="en-gb">
        <ThemeProvider theme={inputTheme}>
          <div className={styles["switch"]}>
            <div>
              <p>
                <b className={styles["modal-subtitle"]}>
                  <FormattedMessage id="timeDate" />
                </b>
              </p>
            </div>
            <div className={styles["switch-button-outer-container"]}>
              <div>
                <div className={styles["switch-button-inner-container"]}>
                  <button
                    data-cy={"departToggleButton"}
                    name="departureTime"
                    className={styles["switch-button"]}
                    onClick={toggleDepart}
                    onKeyPress={toggleDepart}
                  >
                    <span
                      style={{
                        color: journeyPlan.departureTime
                          ? theme.theme.primaryColour
                          : "",
                      }}
                      className={
                        journeyPlan.departureTime
                          ? styles["switch-button-span-active"]
                          : styles["switch-button-span-inactive"]
                      }
                    >
                      <FormattedMessage id="time_Depart" />
                    </span>
                  </button>
                  <div className={styles["switch-button-divider"]} />
                  <button
                    data-cy={"arriveToggleButton"}
                    name="arrivalTime"
                    className={styles["switch-button"]}
                    onClick={toggleArrive}
                    onKeyPress={toggleArrive}
                  >
                    <span
                      style={{
                        color: journeyPlan.arrivalTime
                          ? theme.theme.primaryColour
                          : "",
                      }}
                      className={
                        journeyPlan.arrivalTime
                          ? styles["switch-button-span-active"]
                          : styles["switch-button-span-inactive"]
                      }
                    >
                      <FormattedMessage id="time_Arrive" />
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <DatePicker value={dateTime} onChange={onDatePickerDateChange} />
          <div className={styles["input-time-outer-container"]}>
            <div className={styles["input-time-inner-container"]}>
              <TimeField
                value={dateTime.format("HH:mm")}
                className={styles["input-time"]}
                onChange={handleTimeChange}
                input={<input type="text" inputMode="numeric" />}
              />
            </div>
          </div>
          {theme.showReturnButton && (
            <Collapse defaultExpanded={props.isReturn}>
              {({ getToggleProps, getCollapseProps, isExpanded }) => {
                setIsReturn(isExpanded);
                return (
                  <>
                    <div className={styles["title-container"]}>
                      <p>
                        <div
                          {...getToggleProps()}
                          className={styles["title-custom-cursor"]}
                          tabIndex={0}
                        >
                          <span>
                            <FontAwesomeIcon
                              icon={isExpanded ? "arrow-down" : "arrow-right"}
                            />
                            <b className={styles["modal-subtitle-icon"]}>
                              Return Journey
                            </b>
                          </span>
                        </div>
                      </p>
                    </div>
                    <section {...getCollapseProps()}>
                      <div className={styles["switch"]}>
                        <div>
                          <p>
                            <b className={styles["modal-subtitle"]}>
                              Return Date & Time
                            </b>
                          </p>
                        </div>
                        <div
                          className={styles["switch-button-outer-container"]}
                        >
                          <div>
                            <div
                              className={
                                styles["switch-button-inner-container"]
                              }
                            >
                              <button
                                data-cy={"departToggleButton"}
                                name="returnDepartureTime"
                                className={styles["switch-button"]}
                                onClick={toggleReturnDepart}
                                onKeyPress={toggleReturnDepart}
                              >
                                <span
                                  style={{
                                    color: journeyPlan.returnDepartureTime
                                      ? theme.theme.primaryColour
                                      : "",
                                  }}
                                  className={
                                    journeyPlan.returnDepartureTime
                                      ? styles["switch-button-span-active"]
                                      : styles["switch-button-span-inactive"]
                                  }
                                >
                                  <FormattedMessage id="time_Depart" />
                                </span>
                              </button>
                              <div
                                className={styles["switch-button-divider"]}
                              />
                              <button
                                data-cy={"arriveToggleButton"}
                                name="returnArrivalTime"
                                className={styles["switch-button"]}
                                onClick={toggleReturnArrive}
                                onKeyPress={toggleReturnArrive}
                              >
                                <span
                                  style={{
                                    color: journeyPlan.returnArrivalTime
                                      ? theme.theme.primaryColour
                                      : "",
                                  }}
                                  className={
                                    journeyPlan.returnArrivalTime
                                      ? styles["switch-button-span-active"]
                                      : styles["switch-button-span-inactive"]
                                  }
                                >
                                  <FormattedMessage id="time_Arrive" />
                                </span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <DatePicker
                        value={returnDateTime}
                        onChange={onReturnDatePickerDateChange}
                      />
                      <div className={styles["input-time-outer-container"]}>
                        <div className={styles["input-time-inner-container"]}>
                          <TimeField
                            value={
                              returnDateTime
                                ? returnDateTime.format("HH:mm")
                                : 0
                            }
                            className={styles["input-time"]}
                            onChange={handleReturnTimeChange}
                            input={<input type="text" inputMode="numeric" />}
                          />
                        </div>
                      </div>
                    </section>
                  </>
                );
              }}
            </Collapse>
          )}
          {!theme?.hideClientElements?.includes("hideWhosTravelling") ? (
            <>
              <div className={styles["title-container"]}>
                <p>
                  <div className={styles["title"]} tabIndex={0}>
                    <span>
                      <b className={styles["modal-subtitle"]}>
                        <FormattedMessage id="whoTravelling" />
                      </b>
                    </span>
                  </div>
                </p>
              </div>
              <div className={styles["numeric-buttons-container"]}>
                <EditPassengers
                  handleAdultFilterQuantity={handleAdultFilterQuantity}
                  handleChildFilterQuantity={handleChildFilterQuantity}
                  filterAdults={state.filterAdults}
                  filterChildren={state.filterChildren}
                />
              </div>
              <div></div>
            </>
          ) : (
            ""
          )}
          {isPTOptions && (
            <Collapse defaultExpanded={theme.expandPTOptions}>
              {({ getToggleProps, getCollapseProps, isExpanded }) => (
                <>
                  <div className={styles["title-container"]}>
                    <p>
                      <div
                        {...getToggleProps()}
                        className={styles["title-custom-cursor"]}
                        tabIndex={0}
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={isExpanded ? "arrow-down" : "arrow-right"}
                          />
                          <b className={styles["modal-subtitle-icon"]}>
                            <FormattedMessage id="ptFilters" />
                          </b>
                        </span>
                      </div>
                    </p>
                  </div>
                  <section {...getCollapseProps()}>
                    <div className={styles["numeric-buttons-container"]}>
                      <EditPublicTransport
                        handlefilterBus={handleBusFilter}
                        handlefilterTram={handleTramFilter}
                        handlefilterTrain={handleTrainFilter}
                        filterBus={props.filterBus}
                        filterTram={props.filterTram}
                        filterTrain={props.filterTrain}
                      />
                    </div>
                  </section>
                </>
              )}
            </Collapse>
          )}
          {isEvDestinationGroup && (
            <Collapse defaultExpanded={false}>
              {({ getToggleProps, getCollapseProps, isExpanded }) => (
                <>
                  <div className={styles["title-container"]}>
                    <p>
                      <div
                        {...getToggleProps()}
                        className={styles["title-custom-cursor"]}
                        tabIndex={0}
                      >
                        <span>
                          <FontAwesomeIcon
                            icon={isExpanded ? "arrow-down" : "arrow-right"}
                          />
                          <b className={styles["modal-subtitle-icon"]}>
                            <FormattedMessage id="evFilters" />
                          </b>
                        </span>
                      </div>
                    </p>
                  </div>
                  <section {...getCollapseProps()}>
                    <div className={styles["numeric-buttons-container"]}>
                      <EditElectricVehicles
                        handleRemainingRangeFilterQuantity={
                          handleRemainingRangeFilterQuantity
                        }
                        handleMaximumRangeFilterQuantity={
                          handleMaximumRangeFilterQuantity
                        }
                        handleChargingTimeFilterQuantity={
                          handleChargingTimeFilterQuantity
                        }
                        handleChademoFilter={handleChademoFilter}
                        handleComboCCSFilter={handleComboCCSFilter}
                        handleRapidAC43Filter={handleRapidAC43Filter}
                        filterRemainingRange={state.filterRemainingRange}
                        filterMaximumRange={state.filterMaximumRange}
                        filterChargingTime={state.filterChargingTime}
                        filterChademo={state.filterChademo}
                        filterComboCCS={state.filterComboCCS}
                        filterRapidAC43={state.filterRapidAC43}
                      />
                    </div>
                  </section>
                </>
              )}
            </Collapse>
          )}

          {/* Additional filters */}

          <div className={styles["numeric-buttons-container"]}>
            {!hideTitle ? (
              <div className={styles["title-container"]}>
                <p>
                  <div className={styles["title"]} tabIndex={0}>
                    <span>
                      <b className={styles["modal-subtitle"]}>
                        <FormattedMessage id="additionalRequirements" />
                      </b>
                    </span>
                  </div>
                </p>
              </div>
            ) : (
              ""
            )}
            {/* filterWheelchair */}
            {!theme?.hideClientElements?.includes("hideStepFree") ? (
              <div
                data-cy={"wheelchairButton"}
                onClick={handleFilter.bind(this, "filterWheelchair")}
                onKeyPress={handleFilter.bind(this, "filterWheelchair")}
                style={{
                  background:
                    state.filterWheelchair > 0
                      ? theme.theme.primaryColour
                      : "white",
                }}
                className={`${styles["numeric-button-container"]} ${
                  state.filterWheelchair ? styles["active"] : styles["inactive"]
                }`}
                tabIndex="0"
              >
                <div className={styles["step-free-container-icon"]}>
                  {state.filterWheelchair > 0 ? (
                    <AccessibilityActive
                      className={styles["numeric-button-container-icon"]}
                    />
                  ) : (
                    <Accessibility
                      className={styles["numeric-button-container-icon"]}
                    />
                  )}
                </div>
                <div className={styles["numeric-button-text-box"]}>
                  <p className={styles["numeric-step-free-button-text-box"]}>
                    <FormattedMessage id="stepFreeAccess" />
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* filterLuggage */}
            {!theme?.hideClientElements?.includes("hideHeavyLuggage") ? (
              <div
                data-cy={"heavyLuggageButton"}
                onClick={handleFilter.bind(this, "filterLuggage")}
                onKeyPress={handleFilter.bind(this, "filterLuggage")}
                style={{
                  background:
                    state.filterLuggage > 0
                      ? theme.theme.primaryColour
                      : "white",
                }}
                className={`${styles["numeric-button-container-right"]} ${
                  state.filterLuggage ? styles["active"] : styles["inactive"]
                }`}
                tabIndex="0"
              >
                <div className={styles["button-container-icon-box"]}>
                  {state.filterLuggage > 0 ? (
                    <LuggageActive
                      className={styles["numeric-button-container-icon"]}
                    />
                  ) : (
                    <Luggage
                      className={styles["numeric-button-container-icon"]}
                    />
                  )}
                </div>
                <div className={styles["numeric-button-text-box"]}>
                  <p className={styles["numeric-button-text"]}>
                    <FormattedMessage id="heavyLuggage" />
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* filterGuideDog */}
            {!theme?.hideClientElements?.includes("hideGuideDog") ? (
              <div
                data-cy={"guideDogButton"}
                onClick={handleFilter.bind(this, "filterGuideDog")}
                onKeyPress={handleFilter.bind(this, "filterGuideDog")}
                style={{
                  background:
                    state.filterGuideDog > 0
                      ? theme.theme.primaryColour
                      : "white",
                }}
                className={`${styles["numeric-button-container"]} ${
                  state.filterGuideDog ? styles["active"] : styles["inactive"]
                }`}
                tabIndex="0"
              >
                <div className={styles["button-container-icon-box"]}>
                  {state.filterGuideDog > 0 ? (
                    <GuideDogActive
                      className={styles["numeric-button-container-icon"]}
                    />
                  ) : (
                    <GuideDog
                      className={styles["numeric-button-container-icon"]}
                    />
                  )}
                </div>
                <div className={styles["numeric-button-text-box"]}>
                  <p className={styles["numeric-button-text"]}>
                    {" "}
                    <FormattedMessage id="haveGuide" />
                  </p>
                  <p className={styles["numeric-button-bottom-text"]}>
                    {" "}
                    <FormattedMessage id="dogWithMe" />
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
            {/* filterBicycle */}
            {!theme?.hideClientElements?.includes("hideBikePark") ? (
              <div
                data-cy={"bicycleParkButton"}
                onClick={handleFilter.bind(this, "filterBicycle")}
                onKeyPress={handleFilter.bind(this, "filterBicycle")}
                style={{
                  background:
                    state.filterBicycle > 0
                      ? theme.theme.primaryColour
                      : "white",
                }}
                className={`${styles["numeric-button-container-right"]} ${
                  state.filterBicycle ? styles["active"] : styles["inactive"]
                }`}
                tabIndex="0"
              >
                <div className={styles["button-container-icon-box"]}>
                  {state.filterBicycle ? (
                    <BicycleActive
                      className={styles["numeric-button-container-icon"]}
                    />
                  ) : (
                    <Bicycle
                      className={styles["numeric-button-container-icon"]}
                    />
                  )}
                </div>
                <div className={styles["numeric-button-text-box"]}>
                  <p className={styles["numeric-button-text"]}>
                    <FormattedMessage id="needPlace" />
                  </p>
                  <p className={styles["numeric-button-bottom-text"]}>
                    <FormattedMessage id="parkMyBicycle" />
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </ThemeProvider>
      </LocalizationProvider>
    </Modal>
  );
};

export default OptionsModal;
